import { Button, Input, Checkbox, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Loader from 'components/Loader/Loader';

import { CustomFilterDropdownProps } from './types';
import useFilterDropdown from './useFilterDropdown';
import './styles.sass';

function CustomFilterDropdown(props: CustomFilterDropdownProps) {
  const { selectedKeys } = props;

  const {
    filterOptions,
    searchQuery,
    handleSearch,
    loading,
    isFirstLoad,
    handleCheckboxChange,
    handleMenuScroll,
    handleReset,
    handleConfirm,
  } = useFilterDropdown(props);

  return (
    <>
      <div className="ant-table-filter-dropdown-search">
        <Input
          placeholder="Search in filters"
          value={searchQuery}
          onChange={handleSearch}
          prefix={<SearchOutlined />}
          className="ant-table-filter-dropdown-search-input"
        />
      </div>

      {loading && isFirstLoad ? (
        <Loader />
      ) : (
        <>
          <Menu
            disabled={loading}
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
            }}
            selectedKeys={selectedKeys.map((item) => item.toString())}
            multiple
            onSelect={(item) => handleCheckboxChange(item.key)}
            onDeselect={(item) => handleCheckboxChange(item.key)}
            onScroll={handleMenuScroll}
            items={filterOptions.map((filter) => ({
              key: filter.value.toString(),
              label: (
                <>
                  <Checkbox
                    checked={selectedKeys.includes(filter.value.toString())}
                  />
                  <span>{filter.text}</span>
                </>
              ),
            }))}
          />

          {loading && <div className="backdrop" />}
        </>
      )}

      <div className="ant-table-filter-dropdown-btns">
        <Button
          size="small"
          type="text"
          disabled={!selectedKeys.length}
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button type="primary" size="small" onClick={handleConfirm}>
          OK
        </Button>
      </div>
    </>
  );
}

export default CustomFilterDropdown;
