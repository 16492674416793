import { Button, Checkbox, Descriptions, Form, Input } from 'antd';
import CorporateClientStatusSelect from '../../../../components/Select/CorporateClientStatusSelect';
import { PaymentProcessors } from '../../../../constants/PaymentProcessors';
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useState } from 'react';
import routes from '../../../../constants/routes';
import { useMutation } from '@apollo/client';
import {
  MUTATION_UPDATE_CORPORATE_CLIENT,
  QUERY_LIST_CORPORATE_CLIENTS_TABLE,
} from '../../../../queries/CorporateClientsQueries';
import { GeneralTabProps } from '../utils/corpClientTypes';

const GeneralTab: React.FC<GeneralTabProps> = ({ user, corpClientData }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();
  const { dbUser } = user;

  const [updateCorporateClient] = useMutation(
    MUTATION_UPDATE_CORPORATE_CLIENT,
    {
      refetchQueries: [{ query: QUERY_LIST_CORPORATE_CLIENTS_TABLE }],
    },
  );

  const corpClient = corpClientData?.getCorporateClient;
  const link = `${routes.TV_HOSTED_PAGE}/${corpClient?.uuid}`;

  const validateDigits = (rule: any, value: string) => {
    const regExp = /^[0-9]{4}$/; // Regex for exactly 4 digits
    if (value && !regExp.test(value)) {
      return Promise.reject('Please enter exactly 4 digits');
    }
    return Promise.resolve();
  };

  const handleEditSubmit = useCallback(
    async (values: any) => {
      setSaving(true);
      await updateCorporateClient({
        variables: {
          corporate_client_id: params.corporate_client_id,
          input: { ...values, status_id: Number(values.status_id) },
        },
      });

      setSaving(false);
      setTimeout(() => {
        navigate(routes.CORPORATE_CLIENTS);
      }, 100);
    },
    [navigate, corpClientData, updateCorporateClient, params],
  );

  return (
    <Descriptions column={1} bordered>
      <Descriptions.Item>
        <Form
          form={form}
          layout="inline"
          name="name"
          onFinish={handleEditSubmit}
          initialValues={{
            name: corpClient?.name,
            status_id: corpClient?.status_id?.toString(),
            lock_code: corpClient?.lock_code,
            locked: corpClient?.locked,
          }}
          style={{ marginTop: -10, marginBottom: -10 }}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                type: 'string',
                required: true,
                min: 1,
                message: 'cannot be empty',
              },
            ]}
            style={{ marginBottom: 10, width: '300px' }}
          >
            <Input
              placeholder={corpClient?.name}
              disabled={saving || !dbUser?.userPermissions.DangerZone}
            />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status_id"
            rules={[{ required: true, message: 'Please select status!' }]}
            style={{ marginBottom: 10, width: '200px' }}
          >
            <CorporateClientStatusSelect
              placeholder="Select status"
              onChange={(val: any) => form.setFieldValue('status_id', val)}
              disabled={!dbUser?.userPermissions.DangerZone}
            />
          </Form.Item>
          <Form.Item
            name="locked"
            label="Locked"
            valuePropName="checked"
            style={{ marginBottom: 10, marginLeft: 50, width: '80px' }}
          >
            <Checkbox
              disabled={saving || !dbUser?.userPermissions.DangerZone}
            />
          </Form.Item>
          <Form.Item
            name="lock_code"
            label="Lock Code"
            rules={[
              ({ getFieldValue }) => ({
                type: 'string',
                max: 4,
                required: !!getFieldValue('locked'),
                message: 'Please set the code if locked is checked!',
              }),
              {
                validator: validateDigits, // Custom validator for 4 digits
              },
            ]}
          >
            <Input
              maxLength={4}
              placeholder={corpClient?.lock_code}
              disabled={saving || !dbUser?.userPermissions.DangerZone}
              style={{ marginBottom: 10, width: '100px' }}
            />
          </Form.Item>
          <Button
            loading={saving}
            type="primary"
            htmlType="submit"
            disabled={!dbUser?.userPermissions.DangerZone}
          >
            Update
          </Button>
        </Form>
      </Descriptions.Item>
      {!!corpClient?.config_parsed?.payment_config
        .default_payment_processor_id && (
        <Descriptions.Item label="Campus Card Provider">
          {
            PaymentProcessors[
              corpClient?.config_parsed?.payment_config
                ?.default_payment_processor_id
            ]
          }
        </Descriptions.Item>
      )}
      <Descriptions.Item label="TV dashboard URL">
        <span style={{ marginRight: 50 }}>
          {process.env.REACT_APP_URI}
          {link}
        </span>
        <Link to={link + '?admin=true'}>Preview</Link>

        <Link
          to={`${link}/share?admin=true`}
          target="_blank"
          style={{ marginLeft: 50 }}
        >
          Generate Shareable Image
        </Link>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default GeneralTab;
