const Permissions: Record<string, number> = {
  AdminLogin: 1,
  ViewCups: 2,
  ManageCups: 3,
  ViewLocations: 4,
  ManageLocations: 5,
  ManageUsers: 6,
  ViewBins: 7,
  ManageBins: 8,
  ViewDeliveryBoxes: 9,
  ManageDeliveryBoxes: 10,
  ViewAssignments: 11,
  ManageAssignments: 12,
  BasicReports: 13,
  AdminReports: 14,
  DangerZone: 15,
  ManageCorporateClients: 16,
  ManageSettings: 17,
  InviteUsers: 18,
  ManageClientUsers: 19,
  RemoveAdminAccess: 20,
};

export default Permissions;
