import gql from 'graphql-tag';

export const QUERY_LIST_CORPORATE_CLIENTS_TABLE = gql`
  query TableListCorporateClients(
    $where: WhereCorporateClientInput
    $pagination: PaginationInput
    $order: [OrderInput!]
  ) {
    tableListCorporateClients(
      where: $where
      pagination: $pagination
      order: $order
    ) {
      total
      list {
        corporate_client_id
        name
        containers_amount
        assignments_amount
        status_id
        status_name
        uuid
      }
    }
  }
`;

export const QUERY_ONE_CORPORATE_CLIENT = gql`
  query OneCorporateClient($corporate_client_id: ID!) {
    getCorporateClient(corporate_client_id: $corporate_client_id) {
      corporate_client_id
      name
      uuid
      lock_code
      locked
      config_parsed {
        payment_config {
          default_payment_processor_id
        }
      }
      userCorporateClients {
        created_on
        user {
          user_id
          email
          full_name
        }
      }
      clientLevels {
        client_level_id
        membershipLevel {
          name
        }
        clientRole {
          name
        }
        rent_duration_days
        rent_duration_max_days
        trial_length_days
        max_containers_per_month
        max_containers_at_a_time
        warning_email_amount
        locked_account_amount
        max_checkouts_per_day
      }
      status_id
      status {
        name
      }
    }
  }
`;

export const MUTATION_UPDATE_CORPORATE_CLIENT = gql`
  mutation UpdateCorporateClient(
    $corporate_client_id: ID!
    $input: CorporateClientInput!
  ) {
    updateCorporateClient(
      corporate_client_id: $corporate_client_id
      input: $input
    ) {
      corporate_client_id
    }
  }
`;
