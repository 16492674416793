import { IUserContext } from 'contexts/UserContext';

export function getUserInitials(user: IUserContext): string | undefined {
  const { dbUser } = user;

  if (!dbUser) {
    return;
  }

  const { email, full_name } = dbUser;

  const trimmedFullName = full_name?.trim();

  if (trimmedFullName) {
    const [first_name, last_name] = trimmedFullName.split(' ');

    if (first_name && last_name) {
      return [first_name[0], last_name[0]].map((l) => l.toUpperCase()).join('');
    }

    if (first_name) {
      return first_name[0].toUpperCase();
    }

    if (last_name) {
      return last_name[0].toUpperCase();
    }
  }

  return email?.[0]?.toUpperCase() || '';
}
