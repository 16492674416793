import React, { useCallback, useState, useEffect, useContext } from 'react';
import {
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
  Checkbox,
  Select,
} from 'antd';
import timezones from 'timezones-list';
import GooglePlaceAutocomplete from 'components/GooglePlaceAutocomplete/GooglePlaceAutocomplete';
import CapabilitiesSelect from 'components/Select/CapabilitiesSelect';
import LocationStatusSelect from 'components/Select/LocationStatusSelect';
import { LocationStatuses } from 'constants/LocationStatuses';
import formatAddress from 'services/formatAddress';
import CorporateClientSelect from '../../../../components/Select/CorporateClientSelect';
import { UserContext } from '../../../../contexts/UserContext';

const { Option } = Select;
const { Title } = Typography;

const LocationForm = ({ handleSubmit, location }: any) => {
  const { dbUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const mode = location ? 'update' : 'create';
  const [form] = Form.useForm();

  useEffect(() => {
    location &&
      form.setFieldsValue({
        name: location.name,
        pretty_name: location.pretty_name,
        status_id: location.status_id ? location.status_id.toString() : null,
        corporate_client_id: location.corporate_client_id
          ? location.corporate_client_id.toString()
          : null,
        low_cup_threshold: location.low_cup_threshold,
        track_inventory: location.track_inventory,
        menu_url: location.menu_url,
        timezone: location.timezone || null,
        address: formatAddress(location),
        capabilities: (location.capabilities || []).map(
          (c: any) => c.capability_id + '',
        ),
      });

    !location &&
      form.setFieldsValue({
        status_id: LocationStatuses.Active.toString(),
        track_inventory: true,
      });

    if (dbUser?.isCorporateAdmin && dbUser?.userCorporateClients?.[0]) {
      form.setFieldsValue({
        corporate_client_id:
          dbUser.userCorporateClients[0]?.corporate_client_id?.toString(),
      });
    }
  }, [form, location, dbUser]);

  const submit = useCallback(
    async (values: any) => {
      setLoading(true);
      const { address, capabilities, status_id, ...attributes } = values;
      let postalCode = address.addressProperties?.postal_code;

      if (!postalCode) {
        const postalCodeObject = address.raw
          ?.map((r: any) => r.address_components)
          .flat()
          .find((c: any) => c.types.find((t: any) => t === 'postal_code'));

        postalCode = postalCodeObject?.short_name;
      }

      await handleSubmit({
        ...attributes,
        status_id,
        activated_on:
          Number(status_id) === LocationStatuses.Active
            ? !location || location?.status_id !== LocationStatuses.Active
              ? new Date()
              : undefined
            : null,
        ...address.addressProperties,
        postal_code: postalCode,
        capabilities,
      });
      setLoading(false);
    },
    [handleSubmit],
  );

  const handleAddressChange = useCallback(
    ({
      target: {
        value: { name, addressProperties },
      },
    }: any) => {
      const [placeName, ...allOtherParts] = name
        .split(',')
        .map((part: string) => part.trim());
      const generatedName = `${placeName} - ${allOtherParts.join(', ')}`;

      if (
        placeName === addressProperties.address_line_1 ||
        placeName === placeName.address_line_2
      ) {
        form.setFieldsValue({ name });
      } else {
        form.setFieldsValue({ name: generatedName, pretty_name: placeName });
      }
    },
    [form],
  );

  return (
    <Row gutter={10} style={{ paddingLeft: '15px', marginBottom: '10px' }}>
      <Form
        form={form}
        layout="vertical"
        name="location_create"
        onFinish={submit}
      >
        <Row>
          <Col span={24}>
            <Title level={2}>
              {mode === 'create'
                ? 'Create Location'
                : `Update Location "${location.pretty_name}"`}
            </Title>
          </Col>
          <Col span={24} style={{ margin: '20px 0' }}>
            <Form.Item
              name="address"
              label="Google maps autocomplete, search for location"
              rules={[{ required: true, message: 'Please select address!' }]}
            >
              <GooglePlaceAutocomplete onChange={handleAddressChange} />
            </Form.Item>

            <Form.Item
              label="Pretty Name"
              name="pretty_name"
              rules={[{ required: true, message: 'Please input name!' }]}
            >
              <Input placeholder="Pretty Name" />
            </Form.Item>

            <Form.Item
              label="Name of Location"
              name="name"
              rules={[{ required: true, message: 'Please input name!' }]}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <Form.Item label="Capabilities" name="capabilities">
              <CapabilitiesSelect placeholder="Select capabilities" />
            </Form.Item>
            <Form.Item
              label="Status"
              name="status_id"
              rules={[{ required: true, message: 'Please select status!' }]}
            >
              <LocationStatusSelect placeholder="Select status" />
            </Form.Item>

            <Form.Item
              label="Menu Url"
              name="menu_url"
              rules={[{ type: 'url', message: 'Please provide valid URL' }]}
            >
              <Input inputMode="url" placeholder="Menu Url" />
            </Form.Item>

            <Form.Item
              label="Track Inventory"
              name="track_inventory"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>

            <Form.Item
              label="Low Container Threshold"
              name="low_cup_threshold"
              rules={[{ required: true, message: 'Please input Threshold!' }]}
            >
              <Input
                type="number"
                placeholder="Input Threshold (default: 10)"
              />
            </Form.Item>

            <Form.Item
              label="Belongs to Corporate Client"
              name="corporate_client_id"
            >
              <CorporateClientSelect
                placeholder="Select Corporate Client"
                allowClear
                clients={
                  dbUser?.isCorporateAdmin
                    ? dbUser?.userCorporateClients
                    : undefined
                }
              />
            </Form.Item>
            <Form.Item
              label="Timezone"
              name="timezone"
              rules={[{ required: true, message: 'Please select timezone!' }]}
            >
              <Select style={{ width: '100%' }} placeholder="Select Timezone">
                {timezones.map((t: any) => (
                  <Option key={t.name} value={t.tzCode}>
                    {t.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Button type="primary" htmlType="submit" loading={loading}>
              {mode === 'create' ? 'Save' : 'Update'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};

export default LocationForm;
