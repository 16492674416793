import React, { useContext } from 'react';
import { Tabs, Typography } from 'antd';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { QUERY_ONE_CORPORATE_CLIENT } from '../../../queries/CorporateClientsQueries';
import { IUserContext, UserContext } from '../../../contexts/UserContext';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import Loader from '../../../components/Loader/Loader';
import GeneralTab from './tabContent/GeneralTab';
import {
  OneCorporateClientResult,
  OneCorporateClientVariables,
} from './utils/corpClientTypes';
import MembershipsTab from './tabContent/MembershipsTab';
import TeamTab from './tabContent/TeamTab';

const { Title } = Typography;

const AssignmentEdit = () => {
  const params = useParams();

  const user = useContext<IUserContext>(UserContext);
  const { dbUser } = user;

  const corpClientQuery = useQuery<
    OneCorporateClientResult,
    OneCorporateClientVariables
  >(QUERY_ONE_CORPORATE_CLIENT, {
    variables: {
      corporate_client_id: params.corporate_client_id,
    },
    fetchPolicy: 'no-cache',
  });

  const { data, error, loading } = corpClientQuery;
  const corpClientData: OneCorporateClientResult | undefined = data;

  const corpClient = data?.getCorporateClient;

  const corpClientId: number = corpClient?.corporate_client_id ?? 0;

  const IsNotEnoughPermissions =
    !dbUser?.userPermissions.ManageCorporateClients ||
    (dbUser?.isCorporateAdmin &&
      !dbUser.userCorporateClients.find(
        (c) => c.corporate_client_id === Number(params.corporate_client_id),
      ));

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Title level={3}>
        ERROR: <b>{error.message}</b>
      </Title>
    );
  }

  if (IsNotEnoughPermissions) {
    return <PermissionsAlert />;
  }

  const items = [
    {
      key: '1',
      label: 'General',
      children: <GeneralTab user={user} corpClientData={corpClientData} />,
    },
    {
      key: '2',
      label: 'Memberships',
      children: <MembershipsTab user={user} corpClientData={corpClientData} />,
    },
    {
      key: '3',
      label: 'Team',
      children: <TeamTab user={user} corpClientId={corpClientId} />,
    },
  ];

  return (
    <div style={{ paddingLeft: 10 }}>
      <Title level={4}>{corpClient?.name}</Title>
      <Tabs items={items} />
    </div>
  );
};

export default AssignmentEdit;
