import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, message, Spin } from 'antd';
import ROUTES from 'constants/routes';

import auth from 'services/Auth/Firebase';

import '../Auth.sass';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { MUTATION_ACCEPT_INVITE } from '../../../queries/UserQueries';

interface InvitedUserProps {
  inviteId: string | undefined;
  inviteEmail: string | undefined;
}

const SignUpPage = () => {
  const { inviteId } = useParams<{ inviteId: string }>();
  const { inviteEmail } = useParams<{ inviteEmail: string }>();

  return (
    <div className="auth-wrapper">
      <img
        className="auth-wrapper__logo"
        src="/images/Usefull_logo.png"
        alt="Usefull logo"
      />
      <div className="auth-wrapper__title">Sign up:</div>
      <SignUpForm inviteId={inviteId} inviteEmail={inviteEmail} />
      <div className="auth-wrapper__subtitle">
        Already a member?
        <Link to={ROUTES.SIGN_IN}> Sign In</Link>
      </div>
    </div>
  );
};

const SignUpForm: React.FC<InvitedUserProps> = ({ inviteId, inviteEmail }) => {
  const navigate = useNavigate();
  const [invitationCode, setInvitationCode] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [messageApi, contextHolder] = message.useMessage();

  const [acceptInvite, { loading }] = useMutation(MUTATION_ACCEPT_INVITE);

  useEffect(() => {
    if (inviteId && inviteEmail) {
      setInvitationCode(inviteId);
      setEmail(inviteEmail);
    }
  }, [navigate, invitationCode, email]);

  const handleSubmit = useCallback(
    async (values: any) => {
      if (invitationCode && email) {
        try {
          await auth.createUserWithEmailAndPassword(
            values.email,
            values.password,
          );
        } catch (e) {
          const errorMessage =
            (e as Error).message || 'An unexpected error occurred.';
          messageApi.error(errorMessage);
          return;
        }

        const firebase_user_id = auth.currentUser?.uid;
        const response = await acceptInvite({
          variables: {
            first_name: values.first_name,
            last_name: values.last_name,
            invitee_uuid: invitationCode,
            firebase_user_id: firebase_user_id,
          },
        });
        const responseData = response?.data?.acceptInvite;
        if (responseData.isSuccess) {
          navigate(ROUTES.HOME);
        } else {
          messageApi.error(responseData.message);
        }
      } else {
        messageApi.error('You can not register without admin invitation');
      }
    },
    [navigate, invitationCode, email],
  );

  if (!email || !inviteId) {
    return (
      <div>
        Invalid invite or email. Please check your link or contact support.
      </div>
    );
  }

  return (
    <>
      {contextHolder}
      <Form layout="vertical" onFinish={handleSubmit} className="signup-form">
        <Form.Item
          name="email"
          label="E-Mail"
          initialValue={email}
          rules={[{ required: true, message: 'Please input your E-Mail!' }]}
        >
          <Input type="email" disabled />
        </Form.Item>
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[{ required: true, message: 'Please input your First Name!' }]}
        >
          <Input type="text" disabled={loading} />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="Last Name"
          rules={[{ required: true, message: 'Please input your Last Name!' }]}
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input.Password disabled={loading} />
        </Form.Item>
        <Form.Item
          name="passwordRepeat"
          label="Password Confirm"
          rules={[
            {
              required: true,
              message: 'Please input your Password Confirm!',
            },
          ]}
        >
          <Input.Password disabled={loading} />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          className="button-primary"
          disabled={loading}
        >
          Sign Up
          <Spin size="small" spinning={loading} className="signup-loader" />
        </Button>
      </Form>
    </>
  );
};

export default SignUpPage;
