import { DocumentNode } from '@apollo/client';
import { ColumnFilterItem, FilterDropdownProps } from 'antd/es/table/interface';

export enum InsertMode {
  Append = 'append',
  Replace = 'replace',
}

export type CustomFilterDropdownProps = FilterDropdownProps & {
  query: DocumentNode;
  normalizeItems: (data: any) => ColumnFilterItem[];
  queryVariables?: Record<string, string | number>;
};
