import { Tooltip } from 'antd';
import React from 'react';

export const UserInfo = ({ currentUser }: any) => {
  const MAX_POPOVER_TEXT_LENGTH = 34;
  const isLongEmail = currentUser?.email?.length > MAX_POPOVER_TEXT_LENGTH;
  const isLongName =
    currentUser?.first_name?.length + currentUser?.last_name?.length >
    MAX_POPOVER_TEXT_LENGTH;

  return (
    <div className="dropdown-user-info">
      {isLongName ? (
        <Tooltip
          title={
            currentUser
              ? `${currentUser?.first_name} ${currentUser?.last_name}`
              : ''
          }
        >
          <div className="user-name">
            {currentUser
              ? `${currentUser.first_name} ${currentUser.last_name}`
              : 'loading...'}
          </div>
        </Tooltip>
      ) : (
        <div className="user-name">
          {currentUser
            ? `${currentUser?.first_name ?? ''} ${
                currentUser?.last_name ?? ''
              }`.trim() || '- not set -'
            : 'loading...'}
        </div>
      )}

      {isLongEmail ? (
        <Tooltip
          title={currentUser && currentUser.email ? currentUser.email : null}
        >
          <div className="user-email">
            {currentUser ? currentUser.email : null}
          </div>
        </Tooltip>
      ) : (
        <div className="user-email">
          {currentUser ? currentUser.email : null}
        </div>
      )}
    </div>
  );
};
