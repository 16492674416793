import { DOUBLE_VALUE_DELIMETER } from './useFilter';

export function getWhereOperation(column: any) {
  return column && column.filterOperation ? column.filterOperation : 'in';
}

export function getWhereValue(column: any, selectedKeys: any[]) {
  switch (column.filterOperation) {
    case 'isNull':
      return Boolean(selectedKeys[0]);

    case 'contains':
    case 'fullNameContains':
      return selectedKeys[0];

    default:
      return selectedKeys;
  }
}

export function getWhereFilter(column: any, value: any) {
  if (!column) {
    return;
  }
  const result: any = {};
  let link = result;

  if (column.filterSubField) {
    column.filterSubField.forEach((key: string) => {
      link[key] = {};
      link = link[key];
    });
  }
  let operation = getWhereOperation(column);
  let resultValue = value;
  if (operation === 'between') {
    // a filter with a BETWEEN operation has a single array element that includes two values separated by a delimiter
    // so need to separate this element with a delimiter
    // in the output we have the start and end value of the range
    resultValue = value[0].split(DOUBLE_VALUE_DELIMETER);
    if (!resultValue[0]) {
      // if there is no sart value, use LT operation
      operation = 'lt';
      resultValue.shift();
    } else if (!resultValue[1]) {
      // if there is no end value, use GT operation
      operation = 'gt';
      resultValue.pop();
    }
    if (resultValue.join('') === '') return result;
    resultValue = resultValue.map((item: string) => Number(item));
    if (operation === 'between') {
      // LT works like `less than`, GT works like `more than`
      // but BETWEEN works as `more than or equal and less than or equal`
      // so we do little tricky)
      resultValue[0] += 0.0000001;
      resultValue[1] -= 0.0000001;
    }
  }
  link[operation] = getWhereValue(column, resultValue);
  return result;
}

export function normalizeOrderValue(sorter: any, key: string | string[]) {
  return typeof sorter === 'boolean'
    ? Array.isArray(key)
      ? key.join('.')
      : key
    : sorter;
}
