import React, { useContext, useEffect, useMemo } from 'react';
import { User } from 'firebase/auth';
import { AuthContext } from './AuthContext';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import Permissions from '../constants/Permissions';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    me {
      user_id
      email
      first_name
      last_name
      full_name
    }
  }
`;

export const GET_CURRENT_USER_PERMISSIONS = gql`
  query GetCurrentUserPermissions {
    myPermissions {
      permission_id
    }
  }
`;

export const GET_CURRENT_USER_CORPORATE_CLIENTS = gql`
  query GetCurrentUserCorporateClients {
    myCorporateClients {
      corporate_client_id
      corporateClient {
        name
        uuid
      }
    }
  }
`;

export interface IUserContext {
  dbUser?: {
    user_id: number;
    email: string;
    first_name: string;
    last_name: string;
    full_name: string;

    google_place_id?: string;

    permissions: Array<{
      permission_id: number;
    }>;
    userCorporateClients: Array<{
      corporate_client_id: number;
      corporateClient: {
        name: string;
        uuid: string;
      };
    }>;
    isCorporateAdmin: boolean;
    userPermissions: Record<string, boolean>;
  };

  firebaseUser?: User;
}

export const UserContext = React.createContext<IUserContext>({
  dbUser: undefined,
  firebaseUser: undefined,
});

export const UserProvider = ({ children }: any) => {
  const { currentUser } = useContext(AuthContext);
  const [getCurrentUser, { data: userData }] = useLazyQuery(GET_CURRENT_USER);
  const [getCurrentUserPermissions, { data: permissionsData }] = useLazyQuery(
    GET_CURRENT_USER_PERMISSIONS,
  );
  const [getCurrentUserCorporateClients, { data: corpClientsData }] =
    useLazyQuery(GET_CURRENT_USER_CORPORATE_CLIENTS);

  useEffect(() => {
    if (currentUser) {
      getCurrentUser();
    }
  }, [currentUser]);

  useEffect(() => {
    if (userData) {
      getCurrentUserPermissions();
      getCurrentUserCorporateClients();
    }
  }, [userData]);

  const value = useMemo(
    () => ({
      dbUser:
        userData && corpClientsData && permissionsData
          ? {
              ...userData.me,
              isCorporateAdmin: !!corpClientsData?.myCorporateClients?.length,
              userPermissions: {
                ...Object.keys(Permissions).reduce(
                  (res: Record<string, any>, name: any) => {
                    res[name] = !!permissionsData?.myPermissions.find(
                      (p: Record<string, any>) =>
                        p.permission_id === Permissions[name],
                    );

                    return res;
                  },
                  [{}],
                ),
              },
              userCorporateClients: corpClientsData?.myCorporateClients,
              permissions: permissionsData?.myPermissions,
            }
          : undefined,
      firebaseUser: currentUser,
    }),
    [userData, currentUser, permissionsData, corpClientsData],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
