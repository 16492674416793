import React, { useEffect, useRef, useState } from 'react';
import { TeamTabProps } from '../utils/corpClientTypes';
import { Button, message, Modal, Spin, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import {
  GET_EXISTING_AND_INVITED_ADMIN_USERS,
  MUTATION_INVITE_USER,
  MUTATION_UPDATE_USER,
  REMOVE_ADMIN_PERMISSIONS,
  RESEND_INVITE,
  REVOKE_INVITE,
} from '../../../../queries/UserQueries';
import { useMutation, useQuery } from '@apollo/client';
import Loader from '../../../../components/Loader/Loader';
import { FIELDS_NAMES } from '../../../../components/Tables/const';
import { Link } from 'react-router-dom';
import routes from '../../../../constants/routes';
import moment from 'moment/moment';
import AdminPermissionsModal, {
  AdminPermissionsModalRef,
} from './AdminPermissionsModal';
import Permissions from '../../../../constants/Permissions';

const TeamTab: React.FC<TeamTabProps> = ({ user, corpClientId }) => {
  const [scroll, setScroll] = useState<any>();
  const modalInviteUserRef = useRef<AdminPermissionsModalRef>(null);
  const modalChangePermissionsRef = useRef<AdminPermissionsModalRef>(null);
  const [resendInviteLoadingState, setResendInviteLoadingState] = useState<
    Record<string, boolean>
  >({});
  const [revokeInviteLoadingState, setRevokeInviteLoadingState] = useState<
    Record<string, boolean>
  >({});
  const [removePermissionsLoadingState, setRemovePermissionsLoadingState] =
    useState<Record<string, boolean>>({});
  const [isRevokeModalVisible, setIsRevokeModalVisible] = useState(false);
  const [isRemovePermissionsModalVisible, setRemovePermissionsModalVisible] =
    useState(false);
  const [selectedEmail, setSelectedEmail] = useState<string>('');
  const [selectedUserId, setSelectedUserId] = useState<number>(0);
  const [messageApi, contextHolder] = message.useMessage();

  const [inviteUser, { error }] = useMutation(MUTATION_INVITE_USER);
  const [updateUser] = useMutation(MUTATION_UPDATE_USER);
  const [removeAdminPermissions, { error: removeAdminPermissionsError }] =
    useMutation(REMOVE_ADMIN_PERMISSIONS);

  const {
    data: adminUsers,
    loading: adminUsersLoading,
    refetch: refetchAdminUsers,
  } = useQuery(GET_EXISTING_AND_INVITED_ADMIN_USERS, {
    variables: {
      corporateClientId: corpClientId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [inviteResendMutation, { error: resendInviteError }] =
    useMutation(RESEND_INVITE);
  const [inviteRevokeMutation, { error: revokeInviteError }] =
    useMutation(REVOKE_INVITE);

  const { dbUser } = user;

  useEffect(() => {
    const calculateScroll = (timeout = 0) =>
      setTimeout(
        () =>
          setScroll({
            y: Math.max(240, window.innerHeight - 375),
            x: 'max-content',
          }),
        timeout,
      );

    calculateScroll();
    window.addEventListener('orientationchange', calculateScroll as any);
    return () =>
      window.removeEventListener('orientationchange', calculateScroll as any);
  }, []);

  const handleResendInvite = async (email: string) => {
    setResendInviteLoadingState((prev) => ({ ...prev, [email]: true }));
    try {
      const response = await inviteResendMutation({
        variables: {
          userEmail: email,
          corporateClientId: corpClientId,
        },
      });

      const responseData = response?.data?.resendInvite;
      if (!resendInviteError) {
        if (responseData.isSuccess) {
          messageApi.success(responseData.message);
          await refetchAdminUsers();
        } else {
          messageApi.error(responseData.message);
        }
      } else {
        messageApi.error('Failed to resend invite');
      }
    } catch (err) {
      console.log('Error:', err);
      messageApi.error('Something went wrong');
    }
    setResendInviteLoadingState((prev) => ({ ...prev, [email]: false }));
  };

  const handleRevokeInvite = async (email: string) => {
    setRevokeInviteLoadingState((prev) => ({ ...prev, [email]: true }));
    try {
      const response = await inviteRevokeMutation({
        variables: {
          userEmail: email,
          corporateClientId: corpClientId,
        },
      });
      const responseData = response?.data?.revokeInvite;
      if (!revokeInviteError) {
        if (responseData.isSuccess) {
          messageApi.success(responseData.message);
          await refetchAdminUsers();
        } else {
          messageApi.error(responseData.message);
        }
      } else {
        messageApi.error('Failed to revoke invite');
      }
    } catch (err) {
      console.log('Error:', err);
      messageApi.error('Failed to revoke invite');
    }
    setRevokeInviteLoadingState((prev) => ({ ...prev, [email]: false }));
  };

  const showRevokeModal = (email: string) => {
    setSelectedEmail(email);
    setIsRevokeModalVisible(true);
  };

  const handleConfirmRevoke = async () => {
    if (selectedEmail) {
      await handleRevokeInvite(selectedEmail); // Call the revoke function
      setIsRevokeModalVisible(false);
      setSelectedEmail('');
    }
  };

  const handleCancelRevoke = () => {
    setIsRevokeModalVisible(false);
    setSelectedEmail('');
  };

  const showRemovePermissionsModal = (email: string, userId: number) => {
    setSelectedEmail(email);
    setSelectedUserId(userId);
    setRemovePermissionsModalVisible(true);
  };

  const handleCancelRemovePermissions = () => {
    setRemovePermissionsModalVisible(false);
    setSelectedEmail('');
    setSelectedUserId(0);
  };

  const handleConfirmRemovePermissions = async () => {
    if (selectedEmail) {
      await handleRemovePermissions(selectedEmail, selectedUserId);
      setRemovePermissionsModalVisible(false);
      setSelectedEmail('');
      setSelectedUserId(0);
    }
  };

  const handleRemovePermissions = async (email: string, userId: number) => {
    setRemovePermissionsLoadingState((prev) => ({ ...prev, [email]: true }));
    try {
      const response = await removeAdminPermissions({
        variables: {
          userId: userId,
          corporateClientId: corpClientId,
        },
      });

      const responseData = response?.data?.removeAdminAccess;

      if (!removeAdminPermissionsError) {
        if (responseData.isSuccess) {
          messageApi.success(responseData.message);
          await refetchAdminUsers();
        } else {
          messageApi.error(responseData.message);
        }
      } else {
        messageApi.error('Failed to remove admin permissions');
      }
    } catch (err) {
      console.log('Error:', err);
      messageApi.error('Failed to remove admin permissions');
    }

    setRemovePermissionsLoadingState((prev) => ({ ...prev, [email]: true }));
  };

  const adminsColumns: ColumnType<any>[] = [
    {
      title: FIELDS_NAMES.NAME,
      dataIndex: 'fullName',
      key: 'full_name',
      render: (text: string, record: any) => {
        const fullName =
          `${record.firstName || ''} ${record.lastName || ''}`.trim() || '-';
        return dbUser?.userPermissions.ManageUsers && fullName !== '-' ? (
          <Link to={routes.USERS + '/' + record.user_id}>{fullName}</Link>
        ) : (
          fullName
        );
      },
    },
    {
      title: FIELDS_NAMES.EMAIL,
      dataIndex: 'email',
      key: 'email',
      render: (text: string) => text || '-',
    },
    {
      title: FIELDS_NAMES.STATUS,
      dataIndex: 'status', // Directly access `status`
      key: 'status',
      render: (text: string) => text || '-',
    },
    {
      title: FIELDS_NAMES.DATE_INVITED,
      dataIndex: 'invitedOn', // Directly access `invitedOn`
      key: 'invitedOn',
      render: (data: string) =>
        data ? moment(data).format('LLL') : '- not set -',
    },
    {
      title: FIELDS_NAMES.ACTIONS,
      dataIndex: 'actions',
      key: 'actions',
      render: (text: string, record: any) => (
        <>
          {dbUser?.userPermissions?.ManageClientUsers &&
            record.user_id !== dbUser?.user_id &&
            record.status === 'Active' && (
              <Button
                type="link"
                onClick={() =>
                  handlePermissionsChangeModalOpen(
                    record.email,
                    record.user_id,
                    corpClientId,
                  )
                }
              >
                Edit Permissions
              </Button>
            )}

          {record.status === 'Active' &&
          record.user_id !== dbUser?.user_id &&
          dbUser?.userPermissions?.ManageClientUsers &&
          dbUser?.userPermissions?.InviteUsers &&
          dbUser?.userPermissions?.RemoveAdminAccess ? (
            <Button
              type="link"
              onClick={() =>
                showRemovePermissionsModal(record.email, record.user_id)
              }
            >
              Remove Permissions
            </Button>
          ) : record.status === 'Active' &&
            record.user_id !== dbUser?.user_id &&
            dbUser?.userPermissions?.RemoveAdminAccess &&
            dbUser?.userPermissions?.InviteUsers &&
            !record.permissions.includes(Permissions.ManageClientUsers) ? (
            <Button
              type="link"
              onClick={() =>
                showRemovePermissionsModal(record.email, record.user_id)
              }
            >
              Remove Permissions
            </Button>
          ) : null}

          {dbUser?.userPermissions?.InviteUsers && (
            <span>
              {record.status === 'Invited' && (
                <Button
                  disabled={revokeInviteLoadingState[record.email]}
                  type="link"
                  onClick={() => showRevokeModal(record.email)}
                >
                  Revoke Invite
                </Button>
              )}

              {record.status !== 'Active' && (
                <Button
                  disabled={resendInviteLoadingState[record.email]}
                  type="link"
                  onClick={() => handleResendInvite(record.email)}
                >
                  Resend Invite
                  {resendInviteLoadingState[record.email] && (
                    <Spin size="small" />
                  )}
                </Button>
              )}
            </span>
          )}
        </>
      ),
    },
  ];

  const handleInviteUser = async (values: {
    email: string;
    permissions: string[];
    accessLevel: number;
  }) => {
    try {
      const response = await inviteUser({
        variables: {
          permissionsTemplateId: values.accessLevel,
          grantedPermissions: values.permissions,
          inviteeEmail: values.email,
          corporateClientId: corpClientId,
        },
      });

      if (error) {
        messageApi.error('Error creating invite');
      } else if (response?.data?.createInvite.isSuccess) {
        messageApi.success(response?.data?.createInvite.message);
        await refetchAdminUsers();
        if (modalInviteUserRef.current) {
          modalInviteUserRef.current.handleCancel();
        }
        if (modalChangePermissionsRef.current) {
          modalChangePermissionsRef.current.handleCancel();
        }
      } else {
        messageApi.error(response?.data?.createInvite.message);
      }
    } catch (err) {
      console.error('Error creating invite:', err);
    }
  };

  const handlePermissionsChange = async (values: {
    userId: number;
    email: string;
    corporateClientId: number;
    permissions: string[];
    accessLevel: number;
  }) => {
    const response = await updateUser({
      variables: {
        user_id: values.userId,
        input: {
          email: values.email,
          permissions: values.permissions,
          userCorporateClients: [values.corporateClientId],
        },
      },
    });

    if (response) {
      messageApi.success('Permissions have been changed successfully');
      await refetchAdminUsers();
      if (modalInviteUserRef.current) {
        modalInviteUserRef.current.handleCancel();
      }
      if (modalChangePermissionsRef.current) {
        modalChangePermissionsRef.current.handleCancel();
      }
    } else {
      messageApi.error('Error during permissions change');
    }
  };

  const handleInviteUserModalOpen = () => {
    if (modalInviteUserRef.current) {
      modalInviteUserRef.current.handleModalOpen();
    }
  };

  const handlePermissionsChangeModalOpen = (
    email: string,
    user_id: number,
    corporateClientId: number,
  ) => {
    if (modalChangePermissionsRef.current) {
      modalChangePermissionsRef.current.handleModalOpen(
        email,
        user_id,
        corporateClientId,
      );
    }
  };

  return (
    <>
      {dbUser?.userPermissions.InviteUsers && (
        <span>
          <Button
            style={{ marginBottom: '15px' }}
            type="primary"
            onClick={handleInviteUserModalOpen}
            disabled={!dbUser?.userPermissions.InviteUsers}
          >
            Invite Admin User
          </Button>
          <AdminPermissionsModal
            title="Invite Usser"
            submitTitle="Invite"
            handleSubmit={handleInviteUser}
            ref={modalInviteUserRef}
          />
        </span>
      )}
      {dbUser?.userPermissions.ManageClientUsers && (
        <AdminPermissionsModal
          title="Change Permissions"
          submitTitle="Change"
          handleSubmit={handlePermissionsChange}
          ref={modalChangePermissionsRef}
        />
      )}
      {adminUsersLoading && !adminUsers && <Loader />}
      {adminUsers && adminUsers?.getExistingAndInvitedAdminUsers?.length > 0 && (
        <div>
          <Table
            tableLayout="fixed"
            scroll={scroll}
            rowKey={(record) => record.email}
            dataSource={adminUsers?.getExistingAndInvitedAdminUsers}
            columns={adminsColumns}
            locale={{
              emptyText: 'There are no admin users yet',
            }}
          />
          <Modal
            title="Confirm Revoke"
            open={isRevokeModalVisible}
            onOk={handleConfirmRevoke}
            onCancel={handleCancelRevoke}
            okText={
              revokeInviteLoadingState[selectedEmail] ? (
                <Spin size="small" />
              ) : (
                'Yes, Revoke'
              )
            }
            cancelText="Cancel"
            okButtonProps={{
              disabled: revokeInviteLoadingState[selectedEmail],
            }}
            cancelButtonProps={{
              disabled: revokeInviteLoadingState[selectedEmail],
            }}
          >
            <p>
              Are you sure you want to revoke the invite for {selectedEmail}?
            </p>
          </Modal>
          <Modal
            title="Confirm Remove Permissions"
            open={isRemovePermissionsModalVisible}
            onOk={handleConfirmRemovePermissions}
            onCancel={handleCancelRemovePermissions}
            okText={
              removePermissionsLoadingState[selectedEmail] ? (
                <Spin size="small" />
              ) : (
                'Yes, Remove'
              )
            }
            cancelText="Cancel"
            okButtonProps={{
              disabled: removePermissionsLoadingState[selectedEmail],
            }}
            cancelButtonProps={{
              disabled: removePermissionsLoadingState[selectedEmail],
            }}
          >
            <p>
              Are you sure you want to remove admin permissions for user{' '}
              {selectedEmail}?
            </p>
          </Modal>
        </div>
      )}
      {contextHolder}
    </>
  );
};

export default TeamTab;
