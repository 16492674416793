import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { debounce } from 'lodash';

import {
  PAGINATION_DEFAULT_PAGE,
  PAGINATION_DEFAULT_SIZE,
} from 'constants/pagination';

import { CustomFilterDropdownProps, InsertMode } from './types';
import { ColumnFilterItem } from 'antd/es/table/interface';

const defaultVariables = {
  pagination: {
    page: PAGINATION_DEFAULT_PAGE,
    size: PAGINATION_DEFAULT_SIZE,
  },
};

export default function useFilterDropdown(options: CustomFilterDropdownProps) {
  const {
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    query,
    normalizeItems,
    queryVariables = {},
  } = options;

  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(PAGINATION_DEFAULT_PAGE);
  const [filterOptions, setFilterOptions] = useState<ColumnFilterItem[]>([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [insertMode, setInsertMode] = useState(InsertMode.Append);

  const updatePaginatedData = (data: any) => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    }

    const normalizedData = normalizeItems?.(data);
    console.log(normalizedData, 'normalizedData');
    setHasNextPage(normalizedData.length > 0);

    if (normalizedData) {
      setFilterOptions((prevItems) => {
        return prevItems && insertMode === InsertMode.Append
          ? [...prevItems, ...normalizedData]
          : [...normalizedData];
      });
    }
  };

  const [fetchItems, { loading }] = useLazyQuery(query, {
    variables: {
      ...defaultVariables,
      q: '',
      ...queryVariables,
    },
    onCompleted(data) {
      updatePaginatedData(data);
    },
  });

  const debouncedSearch = useCallback(debounce(fetchItems, 400), [fetchItems]);

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);
    setInsertMode(InsertMode.Replace);
    setPage(PAGINATION_DEFAULT_PAGE);

    debouncedSearch({ variables: { q: value, ...defaultVariables } });
  };

  const handleCheckboxChange = (value: string) => {
    const newSelectedKeys = selectedKeys.includes(value)
      ? selectedKeys.filter((key) => key !== value)
      : [...selectedKeys, value];
    setSelectedKeys(newSelectedKeys);
  };

  const handleReset = () => {
    setSelectedKeys([]);
    clearFilters?.();
  };

  const handleConfirm = () => {
    confirm();
  };

  const handleMenuScroll = async (event: React.UIEvent<HTMLUListElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    const isBottomOfTheList = scrollHeight - scrollTop <= clientHeight;

    if (isBottomOfTheList && !loading && hasNextPage) {
      setInsertMode(InsertMode.Append);
      fetchItems({
        variables: {
          q: searchQuery,
          pagination: {
            ...defaultVariables.pagination,
            page: page + 1,
          },
          ...queryVariables,
        },
      });
      setPage((page) => page + 1);
    }
  };

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return {
    filterOptions,
    searchQuery,
    handleSearch,
    loading,
    isFirstLoad,
    handleCheckboxChange,
    handleMenuScroll,
    handleReset,
    handleConfirm,
  };
}
