import React, { useContext, useMemo } from 'react';
import CCCTable from '../CCCTable/CCCTable';
import routes from 'constants/routes';
import { Link } from 'react-router-dom';
import { UserContext } from 'contexts/UserContext';
import { FIELDS_NAMES } from './const';
import { QUERY_LIST_CORPORATE_CLIENTS_TABLE } from '../../queries/CorporateClientsQueries';

interface ICorporateClientsTable {
  where?: Record<string, any>;
  hiddenColumns?: FIELDS_NAMES[];
}

const CorporateClientsTable = ({
  where,
  hiddenColumns,
}: ICorporateClientsTable) => {
  const { dbUser } = useContext(UserContext);

  const columns = useMemo(() => {
    return [
      {
        title: FIELDS_NAMES.CLIENT_INFO,
        dataIndex: 'name',
        key: 'name',
        render: (name: string, record: any) =>
          dbUser?.userPermissions.ManageCorporateClients ? (
            <Link
              to={routes.CORPORATE_CLIENTS + '/' + record.corporate_client_id}
            >
              {name}
            </Link>
          ) : (
            name
          ),
        width: 200,
      },
      {
        title: FIELDS_NAMES.STATUS,
        dataIndex: 'status_name',
        key: 'status_id',
        width: 200,
      },
      {
        title: FIELDS_NAMES.NUMBER_OF_ASSIGNMENTS,
        dataIndex: 'assignments_amount',
        width: 200,
      },
      {
        title: FIELDS_NAMES.CLIENT_REPORT,
        render: (record: any) => (
          <Link
            target="_blank"
            to={routes.REPORTS_DASHBOARD + '/' + record.uuid}
          >
            Link
          </Link>
        ),
      },
    ];
  }, [dbUser?.isCorporateAdmin]);

  return (
    <CCCTable
      rowKey="corporate_client_id"
      query={QUERY_LIST_CORPORATE_CLIENTS_TABLE}
      queryKey="tableListCorporateClients"
      where={where}
      columns={columns.filter(
        (column) => !hiddenColumns?.includes(column.title),
      )}
    />
  );
};

export default CorporateClientsTable;
