import { Table } from 'antd';
import React, { useMemo } from 'react';
import { ColumnType } from 'antd/lib/table';
import { FIELDS_NAMES } from '../../../../components/Tables/const';
import numeral from 'numeral';
import { CURRENCY_FORMAT } from '../../../../constants/numberformats';
import { GeneralTabProps } from '../utils/corpClientTypes';

const MembershipsTab: React.FC<GeneralTabProps> = ({
  user,
  corpClientData,
}) => {
  const { dbUser } = user;
  const corpClient = corpClientData?.getCorporateClient;
  const membershipsColumns: ColumnType<any>[] = useMemo(() => {
    const extraColumns = dbUser?.isCorporateAdmin
      ? []
      : [
          {
            title: FIELDS_NAMES.MAX_CONTAINERS_PER_MONTH,
            dataIndex: 'max_containers_per_month',
            key: 'max_containers_per_month',
          },
          {
            title: FIELDS_NAMES.RENT_DURATION_MAX_DAYS,
            dataIndex: 'rent_duration_max_days',
            key: 'rent_duration_max_days',
          },
          {
            title: FIELDS_NAMES.WARNING_EMAIL_AMOUNT,
            dataIndex: 'warning_email_amount',
            key: 'warning_email_amount',
            render: (amount: number) => numeral(amount).format(CURRENCY_FORMAT),
          },
          {
            title: FIELDS_NAMES.LOCKED_ACCOUNT_AMOUNT,
            dataIndex: 'locked_account_amount',
            key: 'locked_account_amount',
            render: (amount: number) => numeral(amount).format(CURRENCY_FORMAT),
          },
        ];

    return [
      {
        title: FIELDS_NAMES.NAME,
        dataIndex: ['membershipLevel', 'name'],
        key: 'membershipLevel.name',
      },
      {
        title: FIELDS_NAMES.ROLE,
        dataIndex: ['clientRole', 'name'],
        key: 'clientRole.name',
      },
      {
        title: FIELDS_NAMES.RENT_DURATION_DAYS,
        dataIndex: 'rent_duration_days',
        key: 'rent_duration_days',
      },
      {
        title: FIELDS_NAMES.MAX_CHECKOUTS_PER_DAY,
        dataIndex: 'max_checkouts_per_day',
        key: 'max_checkouts_per_day',
      },
      {
        title: FIELDS_NAMES.MAX_CONTAINERS_AT_A_TIME,
        dataIndex: 'max_containers_at_a_time',
        key: 'max_containers_at_a_time',
      },
      ...extraColumns,
    ];
  }, [dbUser]);

  return (
    <>
      {corpClient?.clientLevels?.length && (
        <Table
          tableLayout="fixed"
          rowKey={(record) => record.client_level_id}
          dataSource={corpClient.clientLevels}
          columns={membershipsColumns}
          pagination={false}
        />
      )}
    </>
  );
};

export default MembershipsTab;
